@import 'webresources-sources/scss/imports';

.separator-component {
  .background {
    height: 20px !important;
    background-image: url('../../images/bordure.svg') !important;

    &[style] {
      background-image: var(--separator-image-url) !important;
    }
  }
}
